<template>
  <td class="text-center px-1 item">
    <template v-if="passagem.placa">
      <v-chip
        class="subtitle-2 px-2 py-1"
        label
        small
        v-b-tooltip.hover.left.html="textoLeituraItem"
        @click="enviarPlaca(passagem.placa)"
        :color="defineCorPlaca()"
      >
        {{ passagem.placa }}
      </v-chip>
    </template>
  </td>
</template>

<script>
import dataFormatada from "@/utils/formatar-data-input";
export default {
  props: {
    passagem: {
      type: Object,
      default: () => {},
    },
    dadosPlacaMeio: {
      type: Array,
      default: () => [],
    },
    maxCoincidencias: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      textoLeituraItem:
        "<p>Total de coincidências: " +
        this.passagem.totalCoincidencia +
        "</p><p>Data Captura: " +
        dataFormatada.ptBrComHorario(this.passagem.data_registro) +
        "</p><p>Diferença de tempo: " +
        this.passagem.data_registro_diferenca +
        "</p>",
    };
  },

  methods: {
    async enviarPlaca(placa) {
      try {
        if (placa === null) {
          return;
        }
        let routeData = this.$router.resolve({
          name: "/?placa=",
          query: { placa: placa },
        });
        window.open(routeData.href, "_blank");
      } catch (e) {
        console.log(e);
      }
    },
    defineCorPlaca() {
      if (this.passagem.totalCoincidencia <= 1) {
        return "grey lighten-2";
      }

      const porcentagem =
        (this.passagem.totalCoincidencia / this.maxCoincidencias) * 100;

      if (porcentagem > 25 && porcentagem <= 50) {
        return "yellow";
      } else if (porcentagem > 50 && porcentagem <= 75) {
        return "orange";
      } else if (porcentagem > 75) {
        return "red";
      }

      return "grey lighten-2";
    },
  },
};
</script>
<style>
.tooltip-inner {
  text-align: left;
  max-width: 100%;
}
p {
  margin: 0 !important;
}
.v-tooltip__content {
  opacity: 1 !important;
}
</style>



